/* eslint-disable no-console,no-undef,camelcase,no-case-declarations */
import * as actionTypes from '../actionTypes';
import { 
  GetAllHRAQuestionAnswers
} from "../../constants/apiConstants";
import { toast } from "react-toastify";
import { http } from '../../http';

const saveMedicalData = (question, answer, isMultipleAnswers, count) => (dispatch) => {
  dispatch({type: actionTypes.SAVE_MEDICAL_DATA, question: question, answer: answer, multipleAnswers: isMultipleAnswers, count: count});
  dispatch(getMedicalData());
};

const getMedicalData = () => (dispatch) => dispatch({type: actionTypes.GET_MEDICAL_DATA});

const saveLifestyleData = (question, answer, isMultipleAnswers, count) => (dispatch) => {
  dispatch({type: actionTypes.SAVE_LIFESTYLE_DATA, question: question, answer: answer, multipleAnswers: isMultipleAnswers, count: count});
  dispatch(getLifestyleData());
};

const getLifestyleData = () => (dispatch) => dispatch({type: actionTypes.GET_LIFESTYLE_DATA});

const saveFitnessData = (question, answer, isMultipleAnswers, count) => (dispatch) => {
  dispatch({type: actionTypes.SAVE_FITNESS_DATA, question: question, answer: answer, multipleAnswers: isMultipleAnswers, count: count});
  dispatch(getFitnessData());
};

const getFitnessData = () => (dispatch) => dispatch({type: actionTypes.GET_FITNESS_DATA});

const saveStressData = (question, answer, isMultipleAnswers, count) => (dispatch) => {
  dispatch({type: actionTypes.SAVE_STRESS_DATA, question: question, answer: answer, multipleAnswers: isMultipleAnswers, count: count});
  dispatch(getStressData());
};

const getStressData = () => (dispatch) => dispatch({type: actionTypes.GET_STRESS_DATA});

const saveNutritionData = (question, answer, isMultipleAnswers, count) => (dispatch) => {
  dispatch({type: actionTypes.SAVE_NUTRITION_DATA, question: question, answer: answer, multipleAnswers: isMultipleAnswers, count: count});
  dispatch(getNutritionData());
};

const getNutritionData = () => (dispatch) => dispatch({type: actionTypes.GET_NUTRITION_DATA});

const saveWorkplaceData = (question, answer, isMultipleAnswers, count) => (dispatch) => {
  dispatch({type: actionTypes.SAVE_WORKPLACE_DATA, question: question, answer: answer, multipleAnswers: isMultipleAnswers, count: count});
  dispatch(getWorkplaceData());
};

const getWorkplaceData = () => (dispatch) => dispatch({type: actionTypes.GET_WORKPLACE_DATA});

const fetchHRAQuestionAnswers = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetAllHRAQuestionAnswers}`, {headers: { AUTHTOKEN: AuthToken }});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({ type: actionTypes.HRA_QUESTIONS_ANSWERS, hraQuestionsAnswersData: res });
      }
    }catch(error){
      toast.error(error.message);
    }
  }
};

export { saveMedicalData, getMedicalData, saveLifestyleData, getLifestyleData, saveFitnessData, getFitnessData, getStressData, saveStressData,
  saveNutritionData, getNutritionData, saveWorkplaceData, getWorkplaceData, fetchHRAQuestionAnswers };