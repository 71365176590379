import { toast } from 'react-toastify';
import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import {GetReadStatusCount, GetUserNotification, UpdateNotificationReadStatus, ReadAllNotifications, 
  DayWiseNotifications, EditSettingsNotifications,UserEmailNotifications,UpdateEmailNotifications} from '../../constants/apiConstants';

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});
const fetchGetUserNotification = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetUserNotification, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_NOTIFICATION_LIST,getUserNotification : res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const notificationReadStatus = (updateData) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateNotificationReadStatus, updateData, config);
      if (res.message) {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}
const getReadStatusCountForNotification = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try{
      const res = await http.get(GetReadStatusCount, {headers: headers});
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_READ_STATUS_COUNT_FOR_NOTIFICATION, readStatusCount: res});
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }
}
const notificationMarkAsRead = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(ReadAllNotifications, '', config);
      if (res.message) {
        toast.error(res.message);
      } else{
        toast.success(res[0]);
        dispatch(fetchGetUserNotification());
        dispatch(getReadStatusCountForNotification());
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}
const GetDayWiseUserNotification = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(DayWiseNotifications, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_DAY_WISE_NOTIFICATIONS, dayWiseUserNotifications : res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const editSettingsNotifications = (Object) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(EditSettingsNotifications, Object, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const  getUserEmailNotifications= () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(UserEmailNotifications, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_USER_EMAIL_NOTIFICATIONS, userEmailNotifications: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const  putUpdateEmailsNotifications = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateEmailNotifications,obj,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch(getUserEmailNotifications());
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
export {fetchGetUserNotification, notificationReadStatus, getReadStatusCountForNotification, notificationMarkAsRead,
  GetDayWiseUserNotification, editSettingsNotifications, getUserEmailNotifications,putUpdateEmailsNotifications
};